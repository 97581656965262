import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class PaymentMethodService{
    getFilteredPaymentData(param){
        const url = `api/accounts/payment/methods/filter`
        const params = {
            'page-index': param.size,
            'branch':param.branch,
            'searched':param.searched,
            'offset':param.offset
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getPaymentElements(){
        const url = `api/accounts/payment/methods/elements`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    createNewPaymentMethod(formData){
        const url = `api/account/payment/method/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formData,headers);
    }
    updatePaymentMethod(formData,id){
        const url = `api/account/payment/method/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formData,headers);
    }
    deletePaymentMethod(id){
        const url = `api/account/payment/method/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.delete(url,headers);
    }
}
export default new PaymentMethodService();