<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Account</a></li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Payment Methods
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Payment Methods</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-7 tx-right">
        <button
        v-if="checkSinglePermission('create_payment_method')"
          type="button"
          class="btn btn-primary mg-t-8 mg-b-0"
          @click="$store.dispatch('modalWithMode', { id: 'addPaymentMethod', mode: 'create' })"
        >
          Add Payment Method
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select v-model="params.size" @change="searchFilter" id="payment_method_filter_size" class="mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select v-model="params.branch" class="filter-input mr-2 mg-t-5" id="payment_method_filter_type" @change="searchFilter">
            <option value="">All Branch</option>
              <option v-for="(type,index) in paymentElements.filter_branchs" :key="index" :value="type.id">{{type.name}}</option>
          </select>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
            <input type="text" v-model="params.searched"  class="float-right" id="payment_method_search" placeholder="Search..." @keyup="searchFilter">
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wp-15p">Payment Method</th>
              <th class="wd-20p">Balance Amount</th>
              <th class="wd-30p">Status</th>
              <th class="wd-10p text-center table-end-item" v-if="checkIfAnyPermission('delete_payment_method','edit_payment_method')">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && paymentDatas.length > 0">
            <tr v-for="(method, index) in paymentDatas" :key="index">
              <th scope="row" class="table-start-account">{{ pageSerialNo+index }}.</th>
              <td>{{method.title}} - {{method.branchs.name}}</td>
              <td>{{parseDigitForSlip(method.closing_balance)}}</td>
              <td><span v-if="method.status == 1">Active</span><span v-else>In-active</span></td>
              <td class="text-center table-end-item" v-if="checkIfAnyPermission('delete_payment_method','edit_payment_method')">
                <!-- <a href="javascript:;" class="mr-3">
                  <i class="fas fa-eye tx-success"></i>
                </a> -->
                <span v-if="method.title != 'Cash'">
                   <a href="javascript:;" v-if="checkSinglePermission('edit_payment_method')" class="mr-3" :id="'methodEdit'+method.id" @click="edit('editPaymentMethod', method.id)" title="Edit">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a href="javascript:;" v-if="checkSinglePermission('delete_payment_method')" @click="drop(method.id)" title="Delete">
                    <i class="fa fa-trash"></i>
                  </a>
                </span>
               
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && paymentDatas.length == 0">
              <tr>
              <td  colspan="11" style="text-align:center;">
                  No records found.
              </td>
              </tr>
          </tbody>
          <tbody v-else-if="loading">
              <tr>
              <td  colspan="11" style="text-align:center;">
                  Loading.....
              </td>
              </tr>
            </tbody>
              <tbody v-else-if="error">
                  <td colspan="6" class="text-center">{{error_message}}</td>
              </tbody>
        </table>
        <Paginate v-model="page" :pageCount="totalPageCount"
                :containerClass="'pagination'"
                :clickHandler="clickCallback"
                v-if="totalPageCount > 1"
                />
      </div>
    </div>
    <Create @new-store="getData()"/>
    <Edit @edit-payment="getData()"/>
  </div>
</template>
<script>
  import Create from "./create";
  import Edit from "./edit";
  import {mapGetters} from "vuex";
  import {Search} from "../../../../../mixins/search";
  import Paginate from 'vuejs-paginate';
  import Services from "./Services/Services";
  import _ from 'lodash';

export default {
  mixins: [Search],
  data(){
    return{
      params:{
          size:10,
          searched:'',
          offset:0,
          branch:'',
      },
      totalPageCount:0,
      pageSerialNo:1,
      page:1,
      loading:true,
      error:false,
      error_message:'',
    }
  },
  components:{
    Create,Paginate,Edit
  },
  methods: {
    clickCallback: function(page) {
      if(page == 1){
          this.pageSerialNo = 1;
      }else{
          this.pageSerialNo = ((page-1) * this.params.size) + 1 ;
      }
      this.params.offset = (page-1) * this.params.size;
      this.getData();
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      // this.$store.commit("setDataId", id);
      // this.$store.commit("dropRecord", `api/account/payment/method/${id}`);
      this.$swal({
        title: 'Do you really want to delete Payment Method ?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Delete',
          cancelButtonText: 'No',
          showLoaderOnConfirm: true,
          preConfirm:() => {
          // delete lead
         return Services.deletePaymentMethod(id).then((response) => {
              if(response.status == 200){
                  let success = response.data.success_message;
                  this.setNotification(success);
              }
              this.getData();
          }).catch(error => { 
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if(error.response.status == 500){
                  let err = error.response.data.error_message;
                  this.setNotification(err);
              }
          })
          }
      })
    },
     searchFilter: _.debounce(function(){
        this.loading = true;
        this.error = false;
        this.page = 1;
        this.pageSerialNo= 1;
        this.params.offset = 0;
        Services.getFilteredPaymentData(this.params).then(res=>{
            this.$store.commit("payments/setPaymentDatas",res.data.data.data);
            this.totalPageCount = res.data.data.total_no_of_pages;
            this.loading=false;
        }).catch(err=>{
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            } else {
              this.error_message = "Error Fetching data from the server.";
            }
            this.error = true;
            let error = err.response.data.error_message;
            this.setNotification(error);
        }).finally(()=>{
          this.loading = false;
        })
      },1000),
    getElements(){
       Services.getPaymentElements().then(res=>{
              this.$store.commit("payments/setPaymentElements",res.data.data);
          }).catch(err=>{
              console.log(err);
          });
    },
    getData(){
      this.loading = true;
      Services.getFilteredPaymentData(this.params).then(res=>{
         this.getElements();
          this.$store.commit("payments/setPaymentDatas",res.data.data.data);
          this.loading = false;
          this.totalPageCount = res.data.data.total_no_of_pages;
      }).catch(err=>{
          if (err.response.data.error_message == "Access Denied.") {
            this.error_message = "Access Denied !!!";
          } else {
            this.error_message = "Error Fetching data from the server.";
          }
          this.error=true;
          this.loading=false;
          console.log(err);
      });
      
      //this.$store.commit("getData", `api/accounts/payment/methods/size/${this.filter.size}`);
    },
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists1",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("payments",["paymentDatas","paymentElements"])
  },
  mounted() {
    this.getData();
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    }
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
<style scoped>
</style>