<template>
    <div class="col-md-12">
        <div class="modal fade modal_cust show" v-if="modalId=='editPaymentMethod'" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" style="display: block;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Edit Payment Method</h5>
                        <a class="close"  @click="$store.dispatch('modalClose')" title="Close">
                            <span aria-hidden="true">×</span>
                        </a>
                    </div>
                    <form @submit.prevent="submit" method="post" class="bg-theam-secondary" enctype="multipart/form-data">
                        <div class="modal-body row">
                            <div class="col-md-6 pd-r-7-5">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="group">
                                            <label class="group-label">Basic Information</label>
                                            <div class="group-attribute-container">
                                                <div class="row mb-2">
                                                    <div class="col-md-5 mr-0 pr-0">Branch</div>
                                                    <div class="col-md-7 ml-0 pl-0">
                                                        <select v-model="formData.branch" class="form-control" title="Please select branch from the list" required>
                                                            <option value="">Select Branch</option>
                                                            <option  v-for="(branch,index) in paymentElements.filter_branchs" :key="index" :value="branch.id">{{branch.name}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-md-5 mr-0 pr-0">Title *</div>
                                                    <div class="col-md-7 input-group ml-0 pl-0">
                                                        <input v-model="formData.title" class="form-control" placeholder="Payment Method Title" required/>
                                                    </div>
                                                </div>
                                                 <div class="row">
                                                    <div class="col-md-5 mr-0 pr-0">Status </div>
                                                    <div class="col-md-7 input-group ml-0 pl-0">
                                                        <select name="status" class="form-control" v-model="formData.status">
                                                            <option value="1">Active</option>
                                                            <option value="0">In-active</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 pd-l-7-5" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="group">
                                            <label class="group-label">Amount Detail</label>
                                            <div class="group-attribute-container">
                                                <div class="row">
                                                    <div class="col-md-5 mr-0 pr-0">Opening Balance</div>
                                                    <div class="col-md-7 ml-0 pl-0" v-if="formData.balance_editable == 0">
                                                        <input type="number" min="0" step="any" v-model="formData.opening_balance" class="form-control"
                                                                placeholder="NRP 0.00">
                                                    </div>
                                                    <div class="col-md-7 ml-0 pl-0" v-else><span class="tx-danger"> Payment Method already used. Cannot update Balance</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 text-right mt-3">
                                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">{{ disableSubmitButton ? 'Submitting....' : 'Submit'}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
// import Services from '../../Supplier/Services/Services';
import Services from "./Services/Services";
export default {
    data(){
        return{
            details:[],
            formData:{
                branch:'',
                title:null,
                status:0,
                balance_editable:'',
                opening_balance:null,
                closing_balance:null,
            },
            disableSubmitButton : false,
        }
    },
    computed: {
        ...mapGetters([
            "processing",
            "eventMessage",
            "dataLists",
            "dataLists1",
            "dataId",
            "modalId",
            "modalMode",
        ]),
        ...mapGetters("payments",['paymentDatas','paymentElements'])
    },
    mounted() {
    },
    methods: {
        submit() {
        this.disableSubmitButton = true;
        Services.updatePaymentMethod(this.formData,this.formData.id).then(res=>{
            this.$store.dispatch("modalClose");
            this.setNotification(res.data.success_message);
            this.$emit('edit-payment');
        }).catch(err=>{
            this.error=false;
            console.log(err);
            if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
            }else{
                let error = err.response.data.error_message;
                 this.setNotification(error);
                console.log(err);
            }
        }).finally(()=>{
          this.disableSubmitButton=false;
        })
        },
        setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
        },
        getElements() {
            Services.getPaymentElements()
            .then((res) => {
                this.$store.commit("payments/setPaymentElements", res.data.data);
                if (this.paymentElements.filter_branchs.length == 1) {
                    this.branchDisabled = true;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },
        clearData(){
            this.formData.branch='';
            this.formData.title=null;
            this.formData.opening_balance=null;
            this.formData.closing_balance=null;
        }
    },
    watch: {
        modalId(value){
            if(value == 'editPaymentMethod'){
                let id = this.dataId;
                this.details = this.paymentDatas.find(function (data) {
                    return data.id == id;
                });
                this.formData={...this.details};
                this.getElements();
            }
        }
    },
}
</script>